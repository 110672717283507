import axios from 'axios';
import moment from 'moment';
import {
	DEFAULT_SORT_KEY_USER,
	DEFAULT_SORT_ORDER,
	GRANT_TYPE,
	httpStatus,
	MAX_LIMIT_RECORD_BULK,
	userSTATUS,
	webPermissions,
} from '../../constants';
import UserService from '../../services/UserService';
import { setActiveUser } from '../../utils/Authentication/UserSession.utils';
import WarningModal from '../../utils/DeleteWarningMessage.utils';
import { ErrorMessage, ErrorModal } from '../../utils/errorMessage';
import SuccessMessage from '../../utils/successMessage';
import UserActionTypes from './User.types';

const userService = new UserService();

export const showUserFromModal = (user = null) => ({
	type: UserActionTypes.SHOW_USER_MODAL_FORM,
	payload: user,
});
// Authentication
export const ssoSignInStart = () => ({
	type: UserActionTypes.SIGN_IN_START,
});
export const ssoSignInSuccess = () => ({
	type: UserActionTypes.SIGN_IN_SUCCESS,
});

export const ssoSignInFailure = errorMessage => ({
	type: UserActionTypes.SIGN_IN_FAILURE,
	payload: errorMessage,
});

// update last login
export const updateLastLoginStart = () => ({
	type: UserActionTypes.USER_UPDATE_LAST_LOGIN_START,
});
export const updateLastLoginSuccess = successMessage => ({
	type: UserActionTypes.USER_UPDATE_LAST_LOGIN_SUCCESS,
	payload: successMessage,
});

export const updateLastLoginFailure = errorMessage => ({
	type: UserActionTypes.USER_UPDATE_LAST_LOGIN_FAILURE,
	payload: errorMessage,
});

export const updateLastLoginStartAsync = (id, email) => dispatch => {
	const formData = {
		email,
		lastLogin: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
	};

	const response = userService.updateUserFactory(id, formData, id, 'login');
	dispatch(updateLastLoginStart());
	response
		.then(successResponse => {
			dispatch(updateLastLoginSuccess(successResponse));
		})
		.catch(error => dispatch(updateLastLoginFailure(error.message)));
};

// Permission

export const fetchLoggedInUserSuccess = loggedInUser => ({
	type: UserActionTypes.FETCH_LOGGED_IN_USER_SUCCESS,
	payload: loggedInUser,
});

export const fetchLoggedInUserFailure = errorMessage => ({
	type: UserActionTypes.FETCH_LOGGED_IN_USER_FAILURE,
	payload: errorMessage,
});

export const setErpType = status => ({
	type: UserActionTypes.SET_ERP_TYPE,
	payload: status,
});

export const setErpTypeAsync = erpType => dispatch => {
	if (erpType) dispatch(setErpType(erpType));
	else
		ErrorMessage(
			'Can not load Default Erp Type please reload the page and try again later',
		);
};

export const ssoSignInStartAsync = code => dispatch => {
	dispatch(ssoSignInStart());
	userService
		.authenticateUser({
			grant_type: GRANT_TYPE,
			client_id: process.env.REACT_APP_SSO_CLIENT_ID,
			client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
			redirect_uri: process.env.REACT_APP_URL,
			code,
		})
		.then(res => res.json())
		.then(res => {
			if (res.error) {
				dispatch(ssoSignInFailure(res.error_description));
			} else {
				setActiveUser(JSON.stringify(res.access_token));
				const permissionData = userService.getLoggedInUser(
					res.access_token.email.toLowerCase(),
				);

				permissionData
					.then(permissionMap => {
						if (
							permissionMap &&
							permissionMap.status === userSTATUS.ACTIVE &&
							permissionMap.web_perms.some(
								userPerms => webPermissions.indexOf(userPerms) >= 0,
							)
						) {
							dispatch(setErpTypeAsync(permissionMap.erp_source));
							dispatch(fetchLoggedInUserSuccess(permissionMap));
							dispatch(ssoSignInSuccess());
							dispatch(
								updateLastLoginStartAsync(
									permissionMap.id,
									permissionMap.email,
								),
							);
						} else {
							dispatch(
								ssoSignInFailure(
									"You don't have permission to use the application or your account has been deactivated",
								),
							);
						}
					})
					.catch(error => dispatch(fetchLoggedInUserFailure(error.message)));
			}
		})
		.catch(error => {
			dispatch(
				ssoSignInFailure(error.error_description || httpStatus.BAD_REQUEST),
			);
		});
};

export const deleteUserStart = () => ({
	type: UserActionTypes.DELETE_USERS_START,
});

export const deleteUserEnd = usersMap => ({
	type: UserActionTypes.DELETE_USERS_SUCCESS,
	payload: usersMap,
});

// search Users Data
export const searchUsersStart = () => ({
	type: UserActionTypes.SEARCH_USERS_START,
});

export const searchUsersSuccess = searchedUsersMap => ({
	type: UserActionTypes.SEARCH_USERS_SUCCESS,
	payload: searchedUsersMap,
});

export const searchUsersFailure = errorMessage => ({
	type: UserActionTypes.SEARCH_USERS_FAILURE,
	payload: errorMessage,
});

export const setUserFilteredInfo = usersMap => ({
	type: UserActionTypes.USER_FILTER_STATUS,
	payload: usersMap,
});

// eslint-disable-next-line arrow-body-style
export const searchUsersStartAsync =
	(
		country,
		searchQuery,
		userStatus,
		offset,
		limit,
		sortKey,
		sortOrder,
		userRole,
	) =>
	dispatch => {
		const usersData = userService.searchUsersFactory(
			country,
			searchQuery,
			userStatus,
			offset,
			limit,
			sortKey,
			sortOrder,
			userRole,
		);
		dispatch(searchUsersStart());
		usersData
			.then(usersMap => {
				dispatch(searchUsersSuccess(usersMap));
			})
			.catch(error => {
				dispatch(searchUsersFailure(error.message));
			});
	};

export const deleteUserListAsync = (data, loginId) => dispatch => {
	WarningModal(`Are you sure you want to Delete ${data?.id}?`, () => {
		const userData = userService.deleteUserListFactory(data?.id, loginId);
		dispatch(deleteUserStart());
		userData
			.then(usersMap => {
				dispatch(deleteUserEnd(usersMap));
				if (usersMap.status === 200) {
					SuccessMessage(
						`User Id '${usersMap?.data?.message.id}' Successfully Removed!`,
					);
				}
			})
			.catch(error => dispatch(searchUsersFailure(error.message)));
	});
};

// Submit  User
export const submitUserStart = () => ({
	type: UserActionTypes.SUBMIT_USER_START,
});

export const submitUserSuccess = successMessage => ({
	type: UserActionTypes.SUBMIT_USER_SUCCESS,
	payload: successMessage,
});
export const EditUserSuccess = successMessage => ({
	type: UserActionTypes.EDIT_USER_SUCCESS,
	payload: successMessage,
});

export const submitUserFailure = errorMessage => ({
	type: UserActionTypes.SUBMIT_USER_FAILURE,
	payload: errorMessage,
});

// eslint-disable-next-line arrow-body-style
export const submitUserStartAsync =
	(data, id = null, loginId = '', country = '') =>
	dispatch => {
		const response = id
			? userService.updateUserFactory(id, data, loginId)
			: userService.createUserFactory(data, loginId);
		dispatch(submitUserStart());
		response
			.then(successResponse => {
				if (!id) {
					dispatch(submitUserSuccess(successResponse));
					SuccessMessage('User successfully created');
				} else {
					dispatch(EditUserSuccess(successResponse));
					SuccessMessage('User successfully updated');
				}
				dispatch(showUserFromModal());
				dispatch(searchUsersStartAsync(country));
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.description &&
					Array.isArray(error.response.data.description)
				) {
					ErrorModal(error.response.data.description[0]);
				} else {
					dispatch(submitUserFailure(error.message));
					ErrorModal(error?.message);
					dispatch(showUserFromModal());
				}
			});
	};

// Send Password Reset Link
export const sendPasswordResetStart = () => ({
	type: UserActionTypes.SEND_PASSWORD_RESET_START,
});

export const sendPasswordResetSuccess = successMessage => ({
	type: UserActionTypes.SEND_PASSWORD_RESET_SUCCESS,
	payload: successMessage,
});

export const sendPasswordResetFailure = errorMessage => ({
	type: UserActionTypes.SEND_PASSWORD_RESET_FAILURE,
	payload: errorMessage,
});

// eslint-disable-next-line arrow-body-style
export const sendPasswordResetLinkAsync = payload => {
	return dispatch => {
		const response = userService.sendPasswordResetLinkFactory(payload);
		dispatch(sendPasswordResetStart());
		response
			.then(successResponse => {
				dispatch(sendPasswordResetSuccess(successResponse));
				dispatch(showUserFromModal());
				SuccessMessage('Set password e-mail successfully sent!');
			})
			.catch(error => {
				dispatch(sendPasswordResetFailure(error.message));
				ErrorMessage('Password reset link has not sent to the user');
			});
	};
};

export const logOut = () => ({
	type: UserActionTypes.USER_LOGOUT,
});

/* Bulk Export  */

export const bulkUserListFailure = errorMessage => ({
	type: UserActionTypes.BULK_EXPORT_USER_LIST_FAILURE,
	payload: errorMessage,
});

export const bulkUserListSuccess = countInstanceMap => ({
	type: UserActionTypes.BULK_EXPORT_USER_LIST_SUCCESS,
	payload: countInstanceMap,
});

export const bulkUserListStart = () => ({
	type: UserActionTypes.BULK_EXPORT_USER_LIST_START,
});

export const bulkExportUsersListSearch =
	(country, totalRecord, searchQuery) => dispatch => {
		const { url } = userService;
		const concatUrl = [];
		const userListData = [];
		for (
			let index = 0;
			index < Math.ceil(totalRecord / MAX_LIMIT_RECORD_BULK);
			index += 1
		) {
			concatUrl.push(
				`${url}?searchFieldKey=${searchQuery.trim()}&sortColumn=${DEFAULT_SORT_KEY_USER}&sortkey=${DEFAULT_SORT_ORDER}&limit=${MAX_LIMIT_RECORD_BULK}&offset=${
					index * MAX_LIMIT_RECORD_BULK
				}&${country}`,
			);
		}
		dispatch(bulkUserListStart());
		Promise.all(concatUrl.map(endpoint => userService.getUserList(endpoint)))
			.then(
				axios.spread((...bulkData) => {
					bulkData.forEach(result => {
						userListData.push(...result.data.searchedData);
					});
					dispatch(
						bulkUserListSuccess({
							result: userListData,
						}),
					);
				}),
			)
			.catch(error => dispatch(bulkUserListFailure(error.message)));
	};

export const setRedirectPath = url => ({
	type: UserActionTypes.USER_REDIRECT_URL,
	payload: url,
});
