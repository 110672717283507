/* eslint-disable sonarjs/no-identical-functions */
import axios from 'axios';
import pLimit from 'p-limit';
import {
	AUDIT_COMPLETED,
	CANCELED,
	CLOSED,
	DEFAULT_SORT_KEY,
	DEFAULT_SORT_ORDER,
	DELETE,
	errorMessages,
	httpStatus,
	IN_PROGRESS,
	MAX_LIMIT_RECORD_BULK,
	RECONCILIATION_IN_PROGRESS,
	UPDATE,
} from '../../constants';
import HttpError from '../../errors/HttpError';
import AuditCountService from '../../services/AuditCountService';
import CountInstanceService from '../../services/CountInstanceService';
import WarningModal from '../../utils/DeleteWarningMessage.utils';
import { ErrorMessage, ErrorModal } from '../../utils/errorMessage';
import SuccessMessage from '../../utils/successMessage';
import { fetchCountInstanceStartAsync } from '../CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.actions';
import CountInstanceActionTypes from './CountInstance.types';

const countInstanceService = new CountInstanceService();
const auditCountService = new AuditCountService();
const promiseLimit = pLimit(1);

const erpTypeSelected = JSON.parse(localStorage.getItem('persist:loggedInUser'))
	?.erpType?.toLowerCase()
	.replace(/[^a-zA-Z 0-9.]+/g, '');

export const addCountInstanceStart = () => ({
	type: CountInstanceActionTypes.ADD_COUNT_INSTANCE_START,
});

export const addCountInstanceSuccess = countInstanceMap => ({
	type: CountInstanceActionTypes.ADD_COUNT_INSTANCE_SUCCESS,
	payload: countInstanceMap,
});

export const addCountInstanceFailure = errorMessage => ({
	type: CountInstanceActionTypes.ADD_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

// search Count instance Data
export const searchCountInstanceStart = () => ({
	type: CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_START,
});

export const searchCountInstanceSuccess = searchedCountInstanceMap => ({
	type: CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_SUCCESS,
	payload: searchedCountInstanceMap,
});

export const searchCountInstanceFailure = errorSearchCountInstanceMap => ({
	type: CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_FAILURE,
	payload: errorSearchCountInstanceMap,
});

// eslint-disable-next-line arrow-body-style
export const searchCountInstanceStartAsync =
	(
		erpType,
		country,
		searchQuery,
		year,
		offset,
		limit,
		sortKey,
		sortOrder,
		filter,
	) =>
	dispatch => {
		const countInstancesResponse =
			countInstanceService.searchCountInstanceFactory(
				erpType,
				country,
				year,
				searchQuery,
				offset,
				limit,
				sortKey,
				sortOrder,
				filter,
			);
		if (!countInstancesResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(searchCountInstanceStart());
		countInstancesResponse
			.then(countInstancesDataMap => {
				dispatch(
					searchCountInstanceSuccess({
						result: countInstancesDataMap.data.searchedData,
						totalCount: countInstancesDataMap.data.searchedCount,
					}),
				);
			})
			.catch(error => dispatch(searchCountInstanceFailure(error.message)));
	};

/* getCountInstanceFilterStart */
export const getCountInstanceFiltersStart = () => ({
	type: CountInstanceActionTypes.GET_COUNT_INSTANCE_FILTERS_START,
});

/* getCountInstanceFilterSuccess */
export const getCountInstanceFiltersSuccess = countInstanceFiltersMap => ({
	type: CountInstanceActionTypes.GET_COUNT_INSTANCE_FILTERS_SUCCESS,
	payload: countInstanceFiltersMap,
});

/* getCountInstanceFilterFailure */
export const getCountInstanceFiltersFailure = errorMessage => ({
	type: CountInstanceActionTypes.GET_COUNT_INSTANCE_FILTERS_FAILURE,
	payload: errorMessage,
});

export const getCountInstanceFiltersStartAsync =
	(erpType, country, selectedYear) => dispatch => {
		const countInstanceFilterResponse =
			countInstanceService.getCountInstanceFilters(
				erpType,
				country,
				selectedYear,
			);
		if (!countInstanceFilterResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(getCountInstanceFiltersStart());
		countInstanceFilterResponse
			.then(countInstanceFiltersMap => {
				dispatch(getCountInstanceFiltersSuccess(countInstanceFiltersMap));
			})
			.catch(error => dispatch(getCountInstanceFiltersFailure(error.message)));
	};
export const postCountInstanceAsync = data => dispatch => {
	const countInstancesData =
		countInstanceService.postCountInstancesFactory(data);
	dispatch(addCountInstanceStart());
	countInstancesData
		.then(countInstanceMap => {
			dispatch(addCountInstanceSuccess(countInstanceMap));
		})
		.catch(error => {
			if (Number(error.response.data.code) === httpStatus.RESOURCE_CONFLICTED)
				ErrorModal(error.response.data.description);
			else ErrorMessage();
			dispatch(addCountInstanceFailure(error.message));
		});
};
// bulk export
export const bulkExportCountInstanceStart = () => ({
	type: CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_START,
});

export const bulkExportCountInstanceSuccess = countInstanceMap => ({
	type: CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_SUCCESS,
	payload: countInstanceMap,
});

export const bulkExportCountInstanceFailure = errorMessage => ({
	type: CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const bulkExportCountInstanceAsync =
	(
		erpType,
		country,
		totalRecord,
		searchQuery,
		year = new Date().getFullYear(),
	) =>
	dispatch => {
		const url = countInstanceService.getErpUrl();
		const concatUrl = [];
		const countInstanceData = [];
		for (
			let index = 0;
			index < Math.ceil(totalRecord / MAX_LIMIT_RECORD_BULK);
			index += 1
		) {
			concatUrl.push(
				`${url}/erps/${erpType.toLowerCase()}/scheduledCount/search?searchField=${searchQuery}&coulumnName=${DEFAULT_SORT_KEY}&sortkey=${DEFAULT_SORT_ORDER}&limit=${MAX_LIMIT_RECORD_BULK}&offset=${
					index * MAX_LIMIT_RECORD_BULK
				}&${country}&year=${year}`,
			);
		}
		dispatch(bulkExportCountInstanceStart());

		const result = Promise.all(
			concatUrl.map(endPoint =>
				promiseLimit(() => countInstanceService.getCountInstance(endPoint)),
			),
		);
		result
			.then(
				axios.spread((...allData) => {
					allData.forEach(element => {
						countInstanceData.push(...element.data.searchedData);
					});
					dispatch(
						bulkExportCountInstanceSuccess({
							result: countInstanceData,
						}),
					);
				}),
			)
			.catch(error => dispatch(bulkExportCountInstanceFailure(error.message)));
	};

export const deleteCountInstanceStart = () => ({
	type: CountInstanceActionTypes.DELETE_COUNT_INSTANCE_START,
});
export const deleteCountInstanceSuccess = successMessage => ({
	type: CountInstanceActionTypes.DELETE_COUNT_INSTANCE_SUCCESS,
	payload: successMessage,
});

export const deleteCountInstanceFailure = errorMessage => ({
	type: CountInstanceActionTypes.DELETE_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const handleDeleteConfirm =
	(country, id, searchQuery, type) => dispatch => {
		if (type === DELETE) {
			const deleteCountInstance =
				countInstanceService.deleteCountInstanceFactory(id);
			if (!deleteCountInstance) {
				throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
			}
			deleteCountInstance
				.then(deleteCountInstanceData => {
					if (deleteCountInstanceData.status === httpStatus.OK)
						SuccessMessage('Successfully Deleted Count Instance');
					dispatch(
						searchCountInstanceStartAsync(
							erpTypeSelected,
							country,
							searchQuery,
						),
					);
				})
				.catch(error => {
					dispatch(deleteCountInstanceFailure(error.message));
					ErrorMessage();
				});
		}
		if (type === UPDATE) {
			const formData = { status: CANCELED };

			const cancelCountInstance =
				countInstanceService.updateCountInstanceFactory(formData, id);
			if (!cancelCountInstance) {
				throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
			}
			cancelCountInstance
				.then(cancelCountInstanceData => {
					if (cancelCountInstanceData.status === httpStatus.OK)
						SuccessMessage('Successfully Canceled Count Instance');
					dispatch(
						searchCountInstanceStartAsync(
							erpTypeSelected,
							country,
							searchQuery,
						),
					);
				})
				.catch(() => ErrorMessage());
		}
	};

export const deleteCountInstanceAsync =
	(country, countInstance) => dispatch => {
		dispatch(deleteCountInstanceStart());

		const auditCountDetailResponse = auditCountService.getAuditCountDetails(
			countInstance.id,
		);

		if (!auditCountDetailResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}

		auditCountDetailResponse
			.then(auditCountDetailData => {
				if (
					countInstance.status === CANCELED ||
					countInstance.status === CLOSED
				) {
					ErrorModal('You Can not delete a canceled or closed count instance!');
				} else if (
					countInstance.status === IN_PROGRESS &&
					(auditCountDetailData === null || auditCountDetailData === '')
				) {
					WarningModal(
						'This count is currently assigned to an operator. Are you sure you want to Delete this Count?',
						() =>
							dispatch(
								handleDeleteConfirm(country, countInstance.id, '', DELETE),
							),
					);
				} else if (
					(countInstance.status === IN_PROGRESS &&
						(auditCountDetailData !== null || auditCountDetailData !== '')) ||
					countInstance.status === RECONCILIATION_IN_PROGRESS ||
					countInstance.status === AUDIT_COMPLETED
				) {
					WarningModal('Count will be set in "Canceled" status. Proceed?', () =>
						dispatch(
							handleDeleteConfirm(country, countInstance.id, '', UPDATE),
						),
					);
				} else {
					WarningModal('Are you sure you want to Delete this Count?', () =>
						dispatch(
							handleDeleteConfirm(country, countInstance.id, '', DELETE),
						),
					);
				}
			})
			.catch(() => ErrorMessage());
	};

export const handleUpdateVarianceAnalysisConfirm = (data, cb) => () => {
	const formData = { status: RECONCILIATION_IN_PROGRESS };

	const reconciliationInstance =
		countInstanceService.updateCountInstanceFactory(formData, data?.id);
	if (!reconciliationInstance) {
		throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
	}
	reconciliationInstance
		.then(reconciliationInstanceData => {
			if (reconciliationInstanceData.status === `success`) {
				SuccessMessage(reconciliationInstanceData.message);
				cb(data);
			}
		})
		.catch(() => ErrorMessage());
};

export const updateVarianceAnalysisAsync = (countInstance, cb) => dispatch => {
	WarningModal(
		'Count will be set in "Reconciliation in Progress" status. Proceed?',
		() => dispatch(handleUpdateVarianceAnalysisConfirm(countInstance, cb)),
	);
};

export const showCloseCountInstanceModal = () => ({
	type: CountInstanceActionTypes.SHOW_CLOSE_COUNT_INSTANCE_MODAL,
});
export const closeCountInstanceStart = () => ({
	type: CountInstanceActionTypes.CLOSE_COUNT_INSTANCE_START,
});
export const closeCountInstanceSuccess = successMessage => ({
	type: CountInstanceActionTypes.CLOSE_COUNT_INSTANCE_SUCCESS,
	payload: successMessage,
});

export const closeCountInstanceFailure = errorMessage => ({
	type: CountInstanceActionTypes.CLOSE_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const closeCountInstanceAsync =
	(closeReason, countInstance) => dispatch => {
		dispatch(closeCountInstanceStart());

		const formData = { status: CLOSED, closeReason };

		const closeCountInstance = countInstanceService.updateCountInstanceFactory(
			formData,
			countInstance.id,
		);

		if (!closeCountInstance) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}

		closeCountInstance
			.then(closeCountInstanceMap => {
				if (closeCountInstanceMap.status === httpStatus.OK)
					dispatch(closeCountInstanceSuccess(closeCountInstanceMap));
				dispatch(fetchCountInstanceStartAsync(countInstance.id));
				dispatch(showCloseCountInstanceModal());
				SuccessMessage('Successfully Closed Count Instance');
			})
			.catch(error => {
				dispatch(closeCountInstanceFailure(error.message));
				ErrorMessage();
			});
	};
