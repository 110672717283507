import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as Info } from '../../../assets/icons/questionMark-icon.svg';
import { postCountInstanceAsync } from '../../../redux/CountInstance/CountInstance.actions';
import {
	selectIsPostingCountInstances,
	selectPostCountInstances,
} from '../../../redux/CountInstance/CountInstance.selectors';
import { showEditCountInstanceModal } from '../../../redux/CountInstanceDetails/CountInstanceDetails.actions';
import { ListOutFranchise } from '../../../utils/UniqueKeyGeneratorFranchiseList.utils';
import { ErrorModal } from '../../../utils/errorMessage';

export function usePreviousState(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
function UniqueKeyForm(props) {
	const {
		visible,
		setUniqueKeyVisible,
		onCancel,
		countId,
		selectedElement,
		postCountInstanceData,
		isPosting,
		showModal,
		countInstancesResponse,
	} = props;
	const [form] = Form.useForm();
	// set the state to check if posting
	const prevState = usePreviousState(isPosting);
	const history = useHistory();

	const handleSubmit = () => {
		const id = [];
		const data = { scheduledCountID: '', locations: '' };
		form.validateFields().then(values => {
			const regex = /^[\p{L}\d_-]+$/u;

			if (countId.concat('_', `${values.uniqueKey}`).length > 30) {
				ErrorModal('Scheduled count ID must be less than 30 characters');
				return;
			}
			const isValidName = regex.test(
				countId.concat('_', `${values.uniqueKey}`),
			);
			if (!isValidName) {
				ErrorModal(
					'Scheduled count ID must not contain special characters or spaces.Please use underscores or hyphens',
				);
				return;
			}
			selectedElement.forEach(element => {
				id.push(element.id);
			});
			const request = {
				...data,
				scheduledCountID: countId.concat('_', `${values.uniqueKey}`),
				locations: id,
			};
			postCountInstanceData(request);
		});
	};

	useEffect(() => {
		if (prevState && countInstancesResponse) {
			history.push(`/smart/count-instance/${countInstancesResponse?.id}`);
			showModal();
			setUniqueKeyVisible(false);
			form.resetFields();
		}
	}, [isPosting]);

	return (
		<Modal
			style={{ width: '538px' }}
			open={visible}
			footer={null}
			onCancel={onCancel}>
			<Form form={form}>
				<div className="p-10">
					<div className="text-jnj_black text-lg font-black pb-4">
						Create a Unique Key
					</div>
					<div className="border-t-2 pb-4" />
					<div className="text-jnj_black text-base font-bold pb-2 ">
						Define a name for each count
					</div>
					<Form.Item
						style={{ marginBottom: '0px' }}
						name="uniqueKey"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}
						colon={false}>
						<div className="flex items-center gap-2">
							<div
								className="p-2 text-base font-normal"
								style={{
									background: '#F4F4F4',
									color: '#63666A',
								}}>{`${countId}_`}</div>

							<div>
								<Input style={{ width: '319px' }} />
							</div>
							<div>
								<Info />
							</div>
						</div>
					</Form.Item>
					<div className="pl-28">{ListOutFranchise(selectedElement)}</div>
					<div className="border-t-2 pb-4" />
					<div className="flex justify-between">
						<div>
							<Button onClick={onCancel} style={{ width: '220px' }}>
								Cancel
							</Button>
						</div>
						<div>
							<Button
								loading={isPosting}
								style={{ width: '220px' }}
								onClick={handleSubmit}
								type="primary">
								Create Count
							</Button>
						</div>
					</div>
				</div>
			</Form>
		</Modal>
	);
}

const mapDispatchToProps = dispatch => ({
	postCountInstanceData: value => dispatch(postCountInstanceAsync(value)),
	showModal: () => dispatch(showEditCountInstanceModal()),
});

const mapStateToProps = createStructuredSelector({
	isPosting: selectIsPostingCountInstances,
	countInstancesResponse: selectPostCountInstances,
});

export default connect(mapStateToProps, mapDispatchToProps)(UniqueKeyForm);
