/* eslint-disable arrow-body-style */
import axios from 'axios';
import { errorMessages, MAX_LIMIT_RECORD_BULK } from '../../../constants';
import HttpError from '../../../errors/HttpError';
import AuditCountService from '../../../services/AuditCountService';
import { ErrorModal } from '../../../utils/errorMessage';
import SuccessMessage from '../../../utils/successMessage';
import { fetchCountInstanceStartAsync } from '../CountInstanceSingle/CountInstanceSingle.actions';
import AuditCountActionTypes from './AuditCount.types';

const auditCountService = new AuditCountService();

export const signatureDownloadStart = () => ({
	type: AuditCountActionTypes.SIGNATURE_DOWNLOAD_START,
});

export const signatureDownloadSuccess = signatureFile => ({
	type: AuditCountActionTypes.SIGNATURE_DOWNLOAD_SUCCESS,
	payload: signatureFile,
});

export const signatureDownloadFailure = downloadError => ({
	type: AuditCountActionTypes.SIGNATURE_DOWNLOAD_FAILURE,
	payload: downloadError,
});

export const signatureDownloadAsync = scheduledCountId => {
	return dispatch => {
		const signatureDownloadRes =
			auditCountService.signatureDownloadFactory(scheduledCountId);
		if (!signatureDownloadRes) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(signatureDownloadStart());
		signatureDownloadRes
			.then(signatureFile => {
				const newBlob = new Blob([signatureFile.data], {
					type: 'application/pdf',
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(newBlob);
					return;
				}
				const data = window.URL.createObjectURL(newBlob);
				const link = document.createElement('a');
				link.href = data;
				link.download = `${scheduledCountId}-Signature`;
				link.click();
				dispatch(signatureDownloadSuccess(signatureFile.data));
			})
			.catch(error => {
				ErrorModal(error.message);
				dispatch(signatureDownloadFailure(error.message));
			});
	};
};

// search Audit count
export const searchAuditCountInstantStart = () => ({
	type: AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_START,
});

export const searchAuditCountInstanceSuccess =
	searchedAuditCountInstanceMap => ({
		type: AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_SUCCESS,
		payload: searchedAuditCountInstanceMap,
	});

export const searchAuditCountInstanceFailure =
	errorAuditSearchCountInstanceMap => ({
		type: AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_FAILURE,
		payload: errorAuditSearchCountInstanceMap,
	});

export const searchAuditCountInstanceStartAsync = (
	id,
	offset,
	limit,
	year,
	searchQuery,
) => {
	return dispatch => {
		const auditCountInstancesResponse =
			auditCountService.searchAuditCountInstanceFactory(
				id,
				offset,
				limit,
				year,
				searchQuery,
			);
		if (!auditCountInstancesResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(searchAuditCountInstantStart());
		auditCountInstancesResponse
			.then(auditCountInstancesSearchDataMap => {
				dispatch(
					searchAuditCountInstanceSuccess({
						result:
							auditCountInstancesSearchDataMap.data.searchedAuditCountData,
						totalCount:
							auditCountInstancesSearchDataMap.data.searchedAuditCount,
					}),
				);
			})
			.catch(error => dispatch(searchAuditCountInstanceFailure(error.message)));
	};
};

// audit bulk export
export const bulkExportAuditCountInstanceStart = () => ({
	type: AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_START,
});

export const bulkExportAuditCountInstanceSuccess = auditCountInstanceMap => ({
	type: AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_SUCCESS,
	payload: auditCountInstanceMap,
});

export const bulkExportAuditCountInstanceFailure = errorMessage => ({
	type: AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_FAILURE,
	payload: errorMessage,
});

export const bulkExportAuditCountInstanceAsync =
	(totalRecord, id, searchQuery, year) => dispatch => {
		const concatUrl = [];
		const auditCountData = [];
		const { url } = auditCountService;

		for (
			let index = 0;
			index < Math.ceil(totalRecord / MAX_LIMIT_RECORD_BULK);
			index += 1
		) {
			concatUrl.push(
				`${url}/erp-auditCount?countType=auditCount&id=${id}&searchField=${searchQuery}&limit=${MAX_LIMIT_RECORD_BULK}&offset=${
					index * MAX_LIMIT_RECORD_BULK
				}&year=${year}`,
			);
		}
		dispatch(bulkExportAuditCountInstanceStart());
		Promise.all(
			concatUrl.map(endpoint => auditCountService.getAuditCount(endpoint)),
		)
			.then(
				axios.spread((...bulkData) => {
					bulkData.forEach(result => {
						auditCountData.push(...result.data.searchedAuditCountData);
					});
					dispatch(
						bulkExportAuditCountInstanceSuccess({
							result: auditCountData,
						}),
					);
				}),
			)
			.catch(error =>
				dispatch(bulkExportAuditCountInstanceFailure(error.message)),
			);
	};

export const csvUploadAuditStart = () => ({
	type: AuditCountActionTypes.CSV_UPLOAD_AUDIT_COUNT_START,
});

export const csvUploadAuditSuccess = auditMap => ({
	type: AuditCountActionTypes.CSV_UPLOAD_AUDIT_COUNT_SUCCESS,
	payload: auditMap,
});

export const csvUploadAuditFailure = () => ({
	type: AuditCountActionTypes.CSV_UPLOAD_AUDIT_COUNT_FAILURE,
	payload: false,
});

const uploadAuditFactoryStatusCheckRecursive = (id, dispatch) => {
	const auditDataStatus = auditCountService.uploadAuditFactoryStatus(id);
	auditDataStatus
		.then(({ status, message }) => {
			if (status === 'In Progress') {
				uploadAuditFactoryStatusCheckRecursive(id, dispatch);
			} else if (status === 'Failed') {
				dispatch(csvUploadAuditFailure());
				ErrorModal(`${message}`);
				dispatch(searchAuditCountInstanceStartAsync(id));
				dispatch(fetchCountInstanceStartAsync(id));
			} else {
				dispatch(csvUploadAuditSuccess({ status, message }));
				SuccessMessage(`${message}`);
				dispatch(searchAuditCountInstanceStartAsync(id));
				dispatch(fetchCountInstanceStartAsync(id));
			}
		})
		.catch(() => {
			uploadAuditFactoryStatusCheckRecursive(id, dispatch);
		});
};

export const uploadAuditAsync = (uploadedAuditData, id) => dispatch => {
	const formData = new FormData();
	formData.append('file', uploadedAuditData);
	const auditData = auditCountService.uploadAuditFactory(formData, id);
	dispatch(csvUploadAuditStart());
	auditData
		.then(() => {
			uploadAuditFactoryStatusCheckRecursive(id, dispatch);
		})
		.catch(error => {
			ErrorModal(`${error.message}`);
			dispatch(csvUploadAuditFailure());
		});
};
