export const countryCodes = {
	US: 'US',
	UK: 'UK',
	DE: 'DE',
};

export const dateFormats = {
	US: 'MM-DD-YYYY',
	DEFAULT: 'DD-MM-YYYY',
};

export const databaseDateFormat = 'YYYY-MM-DD';
export const phoneNumberExts = {
	US: '+1',
	EU: '+86',
	DEFAULT: '+1',
};
export const clusers = [
	['UK', 'CH', 'SA'],
	[
		'SK',
		'DK',
		'LT',
		'SI',
		'CZ',
		'FR',
		'LV',
		'PF',
		'RO',
		'NL',
		'UK',
		'EE',
		'HU',
		'AT',
		'FI',
		'NC',
		'IE',
		'RE',
		'NA',
		'PL',
		'AD',
		'ZA',
		'PT',
		'ES',
		'NO',
		'BG',
		'IT',
		'GP',
		'MQ',
		'TR',
		'GI',
		'HR',
		'SE',
		'BE',
		'CH',
		'SM',
		'GF',
		'DE',
		'LU',
	],
];
export const clusterCommons = ['UK', 'CH'];
