/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/cognitive-complexity */
import axios from 'axios';
import pLimit from 'p-limit';
import {
	errorMessages,
	HEADER_CHECK_VALIDATION_FAILED,
	MAX_LIMIT_RECORD_BULK,
	RECORD_LEVEL_VALIDATION_ERROR,
	TOTAL_EXPECTED_QUANTITY_ERROR,
} from '../../constants';
import HttpError from '../../errors/HttpError';
import VarianceAnalysisService from '../../services/VarianceAnalysisService';
import VarianceAnalysisWorkflowService from '../../services/VarianceAnalysisServiceWorkflow';
import { getActiveUser } from '../../utils/Authentication/UserSession.utils';
import WarningModal from '../../utils/DeleteWarningMessage.utils';
import { ErrorModal } from '../../utils/errorMessage';
import SuccessMessage from '../../utils/successMessage';
import GenerateVarianceAnalysisExportLink, {
	reasonForNotAllowed,
} from '../../utils/VarianceAnalysis.utils';

import VarianceImportError from '../../utils/VarianceImportErrorMessage/VarianceImportErrorMessage.utils';
import { fetchCountInstanceStartAsync } from '../CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.actions';
import VarianceAnalysisTypes from './VarianceAnalysis.types';

const promiseLimit = pLimit(1);

const varianceAnalysisService = new VarianceAnalysisService();
const varianceAnalysisWorkflowService = new VarianceAnalysisWorkflowService();

/* bulkExportVarianceStart */
export const bulkExportInitialVarianceAnalysisStart = type => ({
	type: VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_START,
	payload: type,
});

/* bulkExportVarianceSuccess */
export const bulkExportVarianceAnalysisSuccess = varianceMap => ({
	type: VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_SUCCESS,
	payload: varianceMap,
});

export const bulkExportExcelVarianceAnalysisSuccess = varianceMap => ({
	type: VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_EXCEL_SUCCESS,
	payload: varianceMap,
});
/* bulkExportVarianceFailure */
export const bulkExportVarianceAnalysisFailure = errorMessage => ({
	type: VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_FAILURE,
	payload: errorMessage,
});

export const bulkExportVarianceAnalysisAsync =
	(totalRecord, id, type, sorter, year) => dispatch => {
		const VarianceAnalysis = new VarianceAnalysisService();
		const { url } = VarianceAnalysis;
		const searchedData = [];
		const urls = GenerateVarianceAnalysisExportLink(
			totalRecord,
			id,
			type,
			MAX_LIMIT_RECORD_BULK,
			url,
			sorter,
			year,
		);
		dispatch(bulkExportInitialVarianceAnalysisStart(type));

		const result = Promise.all(
			urls.map(endPoint =>
				promiseLimit(() => VarianceAnalysis.getVarianceAnalysisUrl(endPoint)),
			),
		);
		result
			.then(
				axios.spread((...allData) => {
					allData.forEach(element => {
						searchedData.push(...element.data.varianceData);
					});
					const varianceParsedData = JSON.parse(
						JSON.stringify(searchedData),
					).map(varianceData => {
						varianceData.blindCountValue = parseFloat(
							varianceData.blindCountValue || 0 * 1.0,
						).toFixed(2);
						varianceData.blindCountVarianceValue = parseFloat(
							varianceData.blindCountVarianceValue || 0 * 1.0,
						).toFixed(2);
						return varianceData;
					});
					dispatch(
						bulkExportVarianceAnalysisSuccess({
							VarianceAnalysisData: varianceParsedData,
						}),
					);
					dispatch(
						bulkExportExcelVarianceAnalysisSuccess({
							VarianceAnalysisData: varianceParsedData,
						}),
					);
				}),
			)
			.catch(error =>
				dispatch(bulkExportVarianceAnalysisFailure(error.message)),
			);
	};

// search Variance Analysis Data
export const searchCurrentVarianceAnalysisStart = () => ({
	type: VarianceAnalysisTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_START,
});

export const searchCurrentVarianceAnalysisSuccess =
	searchedVarianceAnalysisMap => ({
		type: VarianceAnalysisTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_SUCCESS,
		payload: searchedVarianceAnalysisMap,
	});

export const searchCurrentVarianceAnalysisFailure =
	errorVarianceAnalysisMap => ({
		type: VarianceAnalysisTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_FAILURE,
		payload: errorVarianceAnalysisMap,
	});

// eslint-disable-next-line arrow-body-style
export const searchCurrentVarianceAnalysisStartAsync =
	({
		countInstanceId,
		searchQuery,
		offset,
		limit,
		sortBy,
		sortKey,
		visualizationMode,
		filter,
		advancedFilter,
		year,
	}) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisService.searchCurrentVarianceAnalysisService({
				countInstanceId,
				searchQuery,
				offset,
				limit,
				sortBy,
				sortKey,
				visualizationMode,
				filter,
				advFilterParams: advancedFilter,
				year,
			});

		if (!varianceAnalysisResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(searchCurrentVarianceAnalysisStart());
		varianceAnalysisResponse
			.then(varianceAnalysisDataMap => {
				dispatch(
					searchCurrentVarianceAnalysisSuccess({
						result: varianceAnalysisDataMap.data,
						totalCount: varianceAnalysisDataMap.data.varianceCount,
					}),
				);
			})
			.catch(error =>
				dispatch(searchCurrentVarianceAnalysisFailure(error.message)),
			);
	};

// search Variance Analysis Franchise SubFranchise
export const getFranchiseSubFranchiseStart = () => ({
	type: VarianceAnalysisTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_START,
});

export const getFranchiseSubFranchiseSuccess =
	successFranchiseSubFranchiseRCodeMap => ({
		type: VarianceAnalysisTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_SUCCESS,
		payload: successFranchiseSubFranchiseRCodeMap,
	});

export const getFranchiseSubFranchiseFailure =
	errorFranchiseSubFranchiseRcodeMap => ({
		type: VarianceAnalysisTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_FAILURE,
		payload: errorFranchiseSubFranchiseRcodeMap,
	});

// eslint-disable-next-line arrow-body-style
export const getFranchiseSubFranchiseRCodeStartAsync =
	(id, erpType) => dispatch => {
		const franchiseSubFranchiseResponse =
			varianceAnalysisService.getFranchiseSubFranchiseRcodeFactory(id, erpType);
		if (!franchiseSubFranchiseResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(getFranchiseSubFranchiseStart());
		franchiseSubFranchiseResponse
			.then(franchiseSubFranchiseMap => {
				dispatch(
					getFranchiseSubFranchiseSuccess({
						result: franchiseSubFranchiseMap.data,
					}),
				);
			})
			.catch(error => dispatch(getFranchiseSubFranchiseFailure(error.message)));
	};
// WorkflowStatus api
export const getVarianceAnalysisSummaryStart = () => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_SUMMARY_START,
});

export const getVarianceAnalysisSummarySuccess = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_SUMMARY_SUCCESS,
	payload: mappedData,
});

export const getVarianceAnalysisSummaryFailure = error => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_SUMMARY_FAILURE,
	payload: error,
});
export const showSummaryModal = () => ({
	type: VarianceAnalysisTypes.SHOW_SUMMARY_MODAL,
});

export const setCountId = id => ({
	type: VarianceAnalysisTypes.SET_COUNT_ID,
	payload: id,
});
export const getVarianceAnalysisSummaryStartAsync = id => dispatch => {
	const varianceAnalysisResponse =
		varianceAnalysisService.getVarianceAnalysisService(id);
	dispatch(getVarianceAnalysisSummaryStart());
	varianceAnalysisResponse
		.then(summaryMap => {
			dispatch(getVarianceAnalysisSummarySuccess(summaryMap));
			dispatch(showSummaryModal());
		})
		.catch(error => dispatch(getVarianceAnalysisSummaryFailure(error.message)));
};

// advanced filter Variance Analysis Data
export const advFilterVarianceAnalysisStart = () => ({
	type: VarianceAnalysisTypes.ADV_FILTER_VARIANCE_ANALYSIS_START,
});

export const advFilterVarianceAnalysisSuccess = advFilterMap => ({
	type: VarianceAnalysisTypes.ADV_FILTER_VARIANCE_ANALYSIS_SUCCESS,
	payload: advFilterMap,
});

export const advFilterVarianceAnalysisFailure = errorVarianceAnalysisMap => ({
	type: VarianceAnalysisTypes.ADV_FILTER_VARIANCE_ANALYSIS_FAILURE,
	payload: errorVarianceAnalysisMap,
});

export const advFilterVarianceAnalysisAsync =
	({ countInstanceId, advFilterParams }) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisService.searchCurrentVarianceAnalysisService({
				countInstanceId,
				advFilterParams,
			});

		if (!varianceAnalysisResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(advFilterVarianceAnalysisStart());
		varianceAnalysisResponse
			.then(varianceAnalysisDataMap => {
				dispatch(
					advFilterVarianceAnalysisSuccess({
						result: varianceAnalysisDataMap.data,
						totalCount: varianceAnalysisDataMap.data.varianceCount,
					}),
				);
			})
			.catch(error =>
				dispatch(advFilterVarianceAnalysisFailure(error.message)),
			);
	};

export const getCSVUploadStatusStart = () => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_START,
});
export const getCSVUploadStatusSuccess = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_SUCCESS,
	payload: mappedData,
});

export const getCSVUploadStatusFailure = error => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_FAILURE,
	payload: error,
});

export const getCSVUploadStatusStartAsync = scheduleCountId => dispatch => {
	const varianceAnalysisUploadStatusResponse =
		varianceAnalysisService.getUploadStatus(scheduleCountId);
	dispatch(getCSVUploadStatusStart());
	varianceAnalysisUploadStatusResponse
		.then(resp => {
			dispatch(getCSVUploadStatusSuccess(resp));
		})
		.catch(error => {
			dispatch(getCSVUploadStatusFailure(error.message));
		});
};
// CSV upload for variance analysis
export const csvUploadVarianceStart = () => ({
	type: VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_START,
});

export const csvUploadVarianceSuccess = successMessage => ({
	type: VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_SUCCESS,
	payload: successMessage,
});

export const csvUploadVarianceFailure = errorMessage => ({
	type: VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_FAILURE,
	payload: errorMessage,
});

export const uploadVarianceAsync =
	(uploadedVarianceDataFile, uploaderUsername, scheduleCountId, dateFormat) =>
	dispatch => {
		const formData = new FormData();
		formData.append('file', uploadedVarianceDataFile);
		const varianceDataFileUpload =
			varianceAnalysisService.uploadVarianceData(formData);
		dispatch(csvUploadVarianceStart());
		varianceDataFileUpload
			.then(() => {
				const varianceData = varianceAnalysisService.uploadVarianceFactory(
					uploadedVarianceDataFile.name,
					uploaderUsername,
					scheduleCountId,
				);
				varianceData
					.then(varianceMap => {
						dispatch(csvUploadVarianceSuccess(varianceMap));
						dispatch(getCSVUploadStatusStartAsync(scheduleCountId));
						SuccessMessage(
							'Records processed successfully it will take some time update the records please check the File Upload status to get the latest status!',
						);
					})
					.catch(error => {
						dispatch(csvUploadVarianceFailure(error.message));
						const errorList = [];
						switch (error.response.data.code) {
							case '500':
								ErrorModal(`${error.message}`);
								break;
							case '400':
								if (
									error?.response?.data?.message ===
									RECORD_LEVEL_VALIDATION_ERROR
								) {
									const errorObject = error.response.data.description;
									Object.entries(errorObject).forEach(([key, value]) => {
										if (typeof value === 'object') {
											Object.entries(value).map(([innerKey, innerValue]) => {
												let record = null;
												if (innerKey === 'fieldsFailed') {
													const mergedKey = `${key}-${innerKey}`;
													record = {
														fieldName: mergedKey,
														errorValue: innerValue,
													};
													errorList.push(record);
												}
												return record;
											});
										} else {
											let record = null;
											record = { fieldName: key, errorValue: value };
											errorList.push(record);
										}
										return errorList;
									});
									VarianceImportError(errorList, dateFormat);
								} else if (
									error.response.data.message === TOTAL_EXPECTED_QUANTITY_ERROR
								) {
									WarningModal(
										`${error.response.data.description} are you sure you want to proceed?`,
										() => {
											const varianceUploadDataWithNoValidation =
												varianceAnalysisService.uploadVarianceNonValidatedFactory(
													uploadedVarianceDataFile.name,
													uploaderUsername,
													scheduleCountId,
												);
											varianceUploadDataWithNoValidation
												.then(() => {
													SuccessMessage('Successfully uploaded data');
												})
												.catch(uploadError => {
													ErrorModal(`${uploadError.message}`);
													dispatch(csvUploadVarianceFailure(error.message));
												});
										},
									);
								} else if (
									error.response.data.message === HEADER_CHECK_VALIDATION_FAILED
								) {
									const errorObject = error.response.data.description;
									Object.entries(errorObject).map(([key, value]) => {
										let record = null;
										record = { fieldName: key, errorValue: value };
										errorList.push(record);
										return errorList;
									});
									VarianceImportError(errorList, dateFormat);
								}
								break;
							default:
								ErrorModal(errorMessages.UNDEFINED_RESPONSE);
								break;
						}
					});
			})
			.catch(error => {
				ErrorModal(`${error.message}`);
				dispatch(csvUploadVarianceFailure(error.message));
			});
	};
/* ============= Variance analysis sorter ================= */
export const setVarianceAnalysisSorter = sorter => ({
	type: VarianceAnalysisTypes.SET_VARIANCE_ANALYSIS_SORTER,
	payload: sorter,
});

/* ============= Variance analysis workflow ================= */
export const getVarianceAnalysisWorkflowStatusStart = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_WORKFLOW_START,
	payload: mappedData,
});
export const getVarianceAnalysisWorkflowStatusSuccess = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_WORKFLOW_SUCCESS,
	payload: mappedData,
});

export const getVarianceAnalysisWorkflowStatusFailure = error => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_WORKFLOW_FAILURE,
	payload: error,
});

export const getVarianceAnalysisWorkflowStartAsync =
	({ scheduledCountId, cb, from }) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisWorkflowService.getVarianceAnalysisWorkflow({
				scheduledCountId,
			});
		dispatch(getVarianceAnalysisWorkflowStatusStart());
		varianceAnalysisResponse
			.then(resp => {
				dispatch(getVarianceAnalysisWorkflowStatusSuccess(resp));
				if (from === 'reject' || from === 'approve') {
					if (from === 'reject') {
						return cb(true);
					}

					if (from === 'approve') {
						return cb(scheduledCountId, {
							action: 'approval',
							approver: `${JSON.parse(getActiveUser()).email.toLowerCase()}`,
						});
					}
					reasonForNotAllowed(from, resp);
				}
				return null;
			})
			.catch(error => {
				dispatch(getVarianceAnalysisWorkflowStatusFailure(error.message));
			});
	};

export const initVarianceAnalysisWorkflowStatusStart = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_START,
	payload: mappedData,
});
export const initVarianceAnalysisWorkflowStatusSuccess = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_SUCCESS,
	payload: mappedData,
});

export const initVarianceAnalysisWorkflowStatusFailure = error => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_FAILURE,
	payload: error,
});

export const initVarianceAnalysisWorkflowStatusStartAsync =
	({ scheduledCountId }) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisWorkflowService.initVarianceAnalysisWorkflow({
				scheduledCountId,
			});
		dispatch(initVarianceAnalysisWorkflowStatusStart());
		varianceAnalysisResponse
			.then(resp => {
				dispatch(showSummaryModal());
				SuccessMessage('Approval sent successfully');
				dispatch(initVarianceAnalysisWorkflowStatusSuccess(resp));
				dispatch(fetchCountInstanceStartAsync(scheduledCountId));
			})
			.catch(error => {
				const respData = error?.response?.data;
				if (respData?.code === '422' || respData?.code === '400') {
					ErrorModal(`${respData?.description}`);
				} else {
					ErrorModal(`${error.message}`);
				}
				dispatch(initVarianceAnalysisWorkflowStatusFailure(error.message));
			});
	};

export const setVarianceAnalysisWorkflowStepStart = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_START,
	payload: mappedData,
});
export const setVarianceAnalysisWorkflowStepSuccess = mappedData => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_SUCCESS,
	payload: mappedData,
});
export const setVarianceAnalysisWorkflowStepFailure = error => ({
	type: VarianceAnalysisTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_FAILURE,
	payload: error,
});

export const setVarianceAnalysisWorkflowStepStartAsync =
	({ scheduledCountId, payload, callBack }) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisWorkflowService.setVarianceAnalysisWorkflowStepFactory({
				scheduledCountId,
				payload,
			});
		dispatch(setVarianceAnalysisWorkflowStepStart());
		varianceAnalysisResponse
			.then(resp => {
				dispatch(setVarianceAnalysisWorkflowStepSuccess(resp));
				if (payload.action !== 'approval') {
					callBack(false);
					SuccessMessage('Rejected.');
				} else {
					SuccessMessage('Approved.');
				}
			})
			.catch(error => {
				const respData = error?.response?.data;
				if (respData?.code === '422') {
					ErrorModal(`${respData?.description}`);
				} else if (respData?.code === '500') {
					ErrorModal(
						`${JSON.parse(respData?.detailedDescription)?.description}`,
					);
				}
				dispatch(setVarianceAnalysisWorkflowStepFailure(error.message));
			});
	};

export const triggerWorkflowRedirectStartAsync =
	({ scheduledCountId, token, payload }) =>
	dispatch => {
		const varianceAnalysisResponse =
			varianceAnalysisWorkflowService.triggerWorkflowStep({
				scheduledCountId,
				token,
				payload,
			});
		dispatch(getVarianceAnalysisWorkflowStatusStart());
		varianceAnalysisResponse
			.then(resp => {
				dispatch(getVarianceAnalysisWorkflowStatusSuccess(resp));
				if (payload.action !== 'approval') {
					SuccessMessage('Rejected.');
				} else {
					SuccessMessage('Approved.');
				}
			})
			.catch(error => {
				const respData = error?.response?.data;
				if (respData?.code === '422') {
					ErrorModal(`${respData?.description}`);
				} else if (respData?.code === '500') {
					ErrorModal(
						`${JSON.parse(respData?.detailedDescription)?.description}`,
					);
				}
				dispatch(getVarianceAnalysisWorkflowStatusFailure(error.message));
			});
	};

export const bulkExportVarianceAnalysisSAPStart = () => ({
	type: VarianceAnalysisTypes.BULK_EXPORT_VARIANCE_ANALYSIS_START,
});
export const bulkExportVarianceAnalysisSAPSuccess = varianceMap => ({
	type: VarianceAnalysisTypes.BULK_EXPORT_VARIANCE_ANALYSIS_SUCCESS,
	payload: varianceMap,
});
export const bulkExportVarianceAnalysisSAPFailure = errorMessage => ({
	type: VarianceAnalysisTypes.BULK_EXPORT_VARIANCE_ANALYSIS_FAILURE,
	payload: errorMessage,
});

export const bulkExportVarianceAnalysisSAPStartAsync =
	(totalRecord, id) => dispatch => {
		const VarianceAnalysis = new VarianceAnalysisService();
		const { url } = VarianceAnalysis;
		const searchedDataSAP = [];

		const urls = [];
		for (
			let index = 0;
			index < Math.ceil(totalRecord / MAX_LIMIT_RECORD_BULK);
			index += 1
		) {
			urls.push(
				`${url}/sap/${id}?limit=${MAX_LIMIT_RECORD_BULK}&offset=${
					MAX_LIMIT_RECORD_BULK * index
				}`,
			);
		}
		dispatch(bulkExportVarianceAnalysisSAPStart());
		const result = Promise.all(
			urls.map(endPoint =>
				promiseLimit(() => VarianceAnalysis.getVarianceAnalysisUrl(endPoint)),
			),
		);
		result
			.then(
				axios.spread((...allData) => {
					allData.forEach(element => {
						searchedDataSAP.push(...element.data.varianceData);
					});
					dispatch(bulkExportVarianceAnalysisSAPSuccess(searchedDataSAP));
				}),
			)
			.catch(error =>
				dispatch(bulkExportVarianceAnalysisSAPFailure(error.message)),
			);
	};
