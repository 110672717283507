import VarianceAnalysisActionTypes from '../VarianceAnalysis.types';

const INITIAL_STATE = {
	currentVarianceAnalysis: null,
	errorMessage: null,
	isFetching: false,
	totalCount: null,
	summaryLoading: false,
	summaryData: null,
	showSummaryModal: false,
	isFranchiseReasonCodeFetching: false,
	franchiseSubFranchiseReasonCode: null,
	isFranchiseSubFranchiseRCodeLoaded: false,
	errorFranchiseReasonCode: null,
	ReasonCode: null,
	isWorkflowFetching: false,
	workflowStatus: null,
	errorWorkflowStatus: null,
	isWorkflowStepFetching: false,
	workflowStep: null,
	workflowStepError: null,
	isWorkflowInitFetching: false,
	workflowInit: null,
	errorWorkflowInit: null,
	bulkExportSAP: null,
	isbulkExportSAPFetching: false,
	bulkExportSAPError: null,
	vaSorter: null,
};

const getCurrentVarianceAnalysisReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case VarianceAnalysisActionTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_START:
		case VarianceAnalysisActionTypes.ADV_FILTER_VARIANCE_ANALYSIS_START:
			return {
				...state,
				isFetching: true,
			};
		case VarianceAnalysisActionTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_SUCCESS:
		case VarianceAnalysisActionTypes.ADV_FILTER_VARIANCE_ANALYSIS_SUCCESS:
			return {
				...state,
				isFetching: false,
				currentVarianceAnalysis: payload.result,
				totalCount: payload.totalCount,
			};
		case VarianceAnalysisActionTypes.SEARCH_CURRENT_VARIANCE_ANALYSIS_FAILURE:
		case VarianceAnalysisActionTypes.ADV_FILTER_VARIANCE_ANALYSIS_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_SUMMARY_START:
			return {
				...state,
				summaryLoading: true,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_SUMMARY_SUCCESS:
			return {
				...state,
				summaryLoading: false,
				summaryData: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_SUMMARY_FAILURE:
			return {
				...state,
				summaryLoading: false,
				errorMessage: payload,
			};
		case VarianceAnalysisActionTypes.SHOW_SUMMARY_MODAL:
			return {
				...state,
				showSummaryModal: !state.showSummaryModal,
			};

		case VarianceAnalysisActionTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_START:
			return {
				...state,
				isFranchiseReasonCodeFetching: true,
				isFranchiseSubFranchiseRCodeLoaded: false,
			};
		case VarianceAnalysisActionTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_SUCCESS:
			return {
				...state,
				isFranchiseReasonCodeFetching: false,
				franchiseSubFranchiseReasonCode: payload.result,
				isFranchiseSubFranchiseRCodeLoaded: true,
			};
		case VarianceAnalysisActionTypes.GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_FAILURE:
			return {
				...state,
				isFranchiseReasonCodeFetching: false,
				errorFranchiseReasonCode: payload,
				isFranchiseSubFranchiseRCodeLoaded: false,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_WORKFLOW_START:
			return {
				...state,
				isWorkflowFetching: true,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_WORKFLOW_SUCCESS:
			return {
				...state,
				isWorkflowFetching: false,
				workflowStatus: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_WORKFLOW_FAILURE:
			return {
				...state,
				isWorkflowFetching: false,
				errorWorkflowStatus: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_START:
			return {
				...state,
				isWorkflowInitFetching: true,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_SUCCESS:
			return {
				...state,
				isWorkflowInitFetching: false,
				workflowInit: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_FAILURE:
			return {
				...state,
				isWorkflowInitFetching: false,
				errorWorkflowInit: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_START:
			return {
				...state,
				isWorkflowStepFetching: true,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_SUCCESS:
			return {
				...state,
				isWorkflowStepFetching: false,
				workflowStep: payload,
			};
		case VarianceAnalysisActionTypes.VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_FAILURE:
			return {
				...state,
				isWorkflowStepFetching: false,
				workflowStepError: payload,
			};
		case VarianceAnalysisActionTypes.BULK_EXPORT_VARIANCE_ANALYSIS_START:
			return {
				...state,
				isbulkExportSAPFetching: true,
			};
		case VarianceAnalysisActionTypes.BULK_EXPORT_VARIANCE_ANALYSIS_SUCCESS:
			return {
				...state,
				isbulkExportSAPFetching: false,
				bulkExportSAP: payload.filter(({ plant }) => !!plant),
			};
		case VarianceAnalysisActionTypes.BULK_EXPORT_VARIANCE_ANALYSIS_FAILURE:
			return {
				...state,
				isbulkExportSAPFetching: false,
				bulkExportSAPError: payload,
			};
		case VarianceAnalysisActionTypes.SET_VARIANCE_ANALYSIS_SORTER:
			return {
				...state,
				vaSorter: payload,
			};
		default:
			return state;
	}
};

export default getCurrentVarianceAnalysisReducer;
