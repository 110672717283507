import { Button, Checkbox, Col, Input, Popover, Row, Space, Table } from 'antd';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as AddLightIcon } from '../../assets/icons/add-light-icon.svg';
import { ReactComponent as DetailIcon } from '../../assets/icons/detail-icon.svg';
import { ReactComponent as EditIconDisabled } from '../../assets/icons/edit-icon-disabled.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import { ReactComponent as FilterColumnIcon } from '../../assets/icons/hide-col-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash-icon.svg';
import {
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	DISCREPANCY_PRODUCT,
	LOT_LEVEL_FIELDS,
	PRODUCT,
	RISK_ASSESSMENT,
	UPLOAD_DISABLED,
	VARIANCE_ANALYSIS,
	userRoles,
} from '../../constants';
import {
	getLocationFiltersStartAsync,
	searchForLocationAsync,
} from '../../redux/Location/Location.action';
import { setErpTypeAsync } from '../../redux/User/User.actions';
import { selectUserCountry } from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import {
	AdvancedFilter,
	ErpSelection,
	ExportData,
	IsCreateCount,
	ShowUpload,
	VisualizationMode,
	disableVarianceAnalysisIcon,
	getBackgroundColor,
	getDeleteButton,
	getEditButton,
	getHasDetail,
	getSelectionType,
	getVarianceButton,
} from '../../utils/DataTable.utils';
import { disableVarAnalysisButton } from '../../utils/VarianceAnalysis.utils';
import YearSelectionComponent from '../YearSelection/YearSelection.component';
import './DataTable.styles.scss';

function DataTable({
	isFetching,
	columnsInit,
	columnsOption,
	selectRecordToEdit,
	data,
	hasDetail,
	deleteData,
	selectionType,
	rowSelection,
	perPage,
	totalRecords,
	resetData,
	searchData,
	title,
	mappedData,
	showUpload,
	createUser,
	createCount,
	isCanCreate,
	exportHeaders,
	callerComponent,
	csvData = data,
	handleTableChange,
	visualizationMode,
	displayCurrency,
	advanceFilter,
	showExportExcelForSAP,
	summaryData,
	signatureDownload,
	signatureDownloadDisabled,
	isSignatureDownloading,
	enableSummary = null,
	varianceAnalysisState,
	loading,
	visualizationModeHandler,
	advanceParams,
	erpSelection,
	setErpType,
	updateErpData,
	updateFilterData,
	country,
	paginate,
	year = new Date().getFullYear(),
	setYear = () => new Date().getFullYear(),
	enableYearSelection = false,
}) {
	/* state for columns */
	const [hiddenCols, setHiddenCols] = useState([]);

	/* Current selected ERP Type */
	const erpType = useSelector(state => state.user.erpType);

	const loggedInUser = useSelector(state => state.user.loggedInUser);

	/* state for search query */
	const [searchQuery, setSearchQuery] = useState('');
	/* use Selector */
	const t = useSelector(selectTranslations);
	/* init history from useHistory */
	const history = useHistory();
	/* select pathname  */
	const { pathname } = history.location;
	/* csv border */
	const csvBorder = (csvData && 'lg:border-l') || '';
	/* visualization Radio Button state */
	const [radioState, setRadioState] = useState('lot');

	/* edit button */
	const editButton = id => (
		<EditIcon
			className="cursor-pointer"
			onClick={() => {
				selectRecordToEdit(id);
			}}
		/>
	);

	const disabledEditButton = () => (
		<EditIconDisabled className="cursor-pointer" />
	);

	const resoveEditLogic = text => {
		if (callerComponent === RISK_ASSESSMENT) {
			return erpSelection &&
				erpType === erpSelection?.defaultSource &&
				year === new Date().getFullYear()
				? getEditButton(selectRecordToEdit, editButton, text)
				: getEditButton(selectRecordToEdit, disabledEditButton, text);
		}
		if (year < new Date().getFullYear()) {
			return getEditButton(selectRecordToEdit, disabledEditButton, text);
		}

		return getEditButton(selectRecordToEdit, editButton, text);
	};
	/* detail button */
	const detailButton = id => (
		<DetailIcon
			className="cursor-pointer"
			onClick={() => {
				history.replace(`${pathname}/${id}`);
			}}
		/>
	);
	/* variance button */
	const varianceButton = text => (
		<button
			disabled={disableVarAnalysisButton(text, text.status)}
			type="button"
			tabIndex={0}
			onClick={() => varianceAnalysisState(text)}>
			{disableVarianceAnalysisIcon(text.status)}
		</button>
	);
	/* delete button */
	const deleteButton = record => (
		<DeleteIcon onClick={() => deleteData(record)} className="cursor-pointer" />
	);
	/* add action method */
	const addActions = column => {
		/* if there is add actions push it to columns init array */
		const actionsColArray = column;
		if (selectRecordToEdit || hasDetail || deleteData) {
			actionsColArray.push({
				title: <div className="flex justify-center font-bold">{t.Action}</div>,
				key: 'action',
				fixed: 'right',
				width: '120px',
				render: ({ key, ...text }) => {
					/* assign scheduled count id  */
					const detailId = text.id;
					/* render acton buttons  */
					return (
						<Space className="flex justify-center" align="end" size="middle">
							{resoveEditLogic(text)}
							{getHasDetail(hasDetail, detailButton, encodeURI(detailId))}
							{getVarianceButton(callerComponent, varianceButton, text)}
							{getDeleteButton(deleteData, deleteButton, text)}
						</Space>
					);
				},
			});
		}
		return actionsColArray;
	};

	const [columns, setColumns] = useState([]);
	useEffect(() => {
		/* add actions col */
		setColumns(addActions(columnsInit));
	}, [erpType, year, columnsInit]);

	useEffect(() => {
		if (year > 0) {
			updateErpData(
				!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
					? country.join(' ')
					: '',
				erpType,
				year,
			);
		}
	}, [year]);

	/* on hide select  */
	const onHideSelect = selection => {
		/* filtered col data index and set to columns state */
		setHiddenCols(selection);
		let filtered;
		if (radioState === 'product' && callerComponent === VARIANCE_ANALYSIS) {
			filtered = columnsInit.filter(
				col =>
					!selection.includes(col.dataIndex) &&
					!LOT_LEVEL_FIELDS.includes(col.dataIndex),
			);
		} else
			filtered = columnsInit.filter(col => !selection.includes(col.dataIndex));
		/* set filtered data */
		if (filtered.filter(col => col.key === 'action').length)
			setColumns(filtered);
		else setColumns(addActions(filtered));
	};
	/* key debounce handler */
	const debouncedSearch = React.useRef(
		debounce(async (query, searchYear) => {
			searchData(query, searchYear);
			setSearchQuery(query);
		}, 500),
	).current;

	const changeErpSelection = e => {
		setErpType(e);
		updateErpData(
			!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
				? country.join(' ')
				: '',
			e,
			year,
		);
		updateFilterData(
			e,
			!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
				? country.join(' ')
				: '',
		);
	};

	/* Radio on change handler visualization mode */
	const radioOnChangeHandler = e => {
		visualizationModeHandler(e);
		setRadioState(e.target.value);
		if (e.target.value === PRODUCT || e.target.value === DISCREPANCY_PRODUCT) {
			const selection = LOT_LEVEL_FIELDS;
			const filtered = columns.filter(
				col => !selection.includes(col.dataIndex),
			);
			setColumns(filtered);
		} else {
			setColumns(
				columnsInit.filter(col => !hiddenCols.includes(col.dataIndex)),
			);
		}
	};

	/* search handler method */
	const onSearchHandler = e => {
		/* reset input key  */
		if (!e.target.value) {
			resetData();
			setSearchQuery('');
		}
		/* set input key  */
		debouncedSearch(e.target.value, year);
	};
	/* useEffect with searchQuery dependency */
	useEffect(() => {
		/* if searchQuery state change call debounceSearch handler */
		debouncedSearch.cancel();
	}, [debouncedSearch]);
	return (
		<div>
			<div>
				<div className="px-5 py-2 text-xl bold">{title}</div>
				<div className="md:flex lg:flex sm:block xs:block pb-10 px-5 justify-between">
					<div className="lg:flex lg:flex-wrap md:block">
						<div>
							{searchData ? (
								<Input
									allowClear
									className="w-52 lg:w-72 h-11 flex-1"
									placeholder={t.Search}
									onChange={e => onSearchHandler(e)}
									suffix={<SearchIcon />}
								/>
							) : null}
						</div>

						<ExportData
							className="flex-auto"
							csvData={csvData}
							exportHeaders={exportHeaders}
							callerComponent={callerComponent}
							mappedData={mappedData}
							searchQuery={searchQuery}
							year={year}
						/>
						<ShowUpload
							className="flex-auto"
							showUpload={
								erpSelection && erpType !== erpSelection?.defaultSource
									? UPLOAD_DISABLED
									: showUpload
							}
							callerComponent={callerComponent}
						/>
						<div className="py-1">
							<Button
								disabled={signatureDownloadDisabled}
								loading={isSignatureDownloading}
								onClick={signatureDownload}
								hidden={!signatureDownload}
								className="w-80 px-0"
								type="primary">
								Download PDF Report W/Signature
							</Button>
						</div>

						<VisualizationMode
							className="flex-auto"
							visualizationMode={visualizationMode}
							onChangeHandler={radioOnChangeHandler}
							state={radioState}
						/>
						{displayCurrency || null}

						{columnsOption ? (
							<Popover
								content={
									<Checkbox.Group
										className="custom-column-visibility-width"
										onChange={onHideSelect}>
										<Row className="md:block lg:flex">
											{columnsOption.map(group => (
												<Col key={group.value} span={8}>
													<Checkbox value={group.value}>{group.label}</Checkbox>
												</Col>
											))}
										</Row>
									</Checkbox.Group>
								}
								trigger="click">
								<span
									className={`h-10 bottom-1 relative ${csvBorder} border-solid`}>
									<Button>
										<span className="pr-5 relative">{t.ColumnVisibility}</span>
										<FilterColumnIcon type="filter" />
									</Button>
								</span>
							</Popover>
						) : null}
						<AdvancedFilter
							className="flex-auto"
							advanceFilter={advanceFilter}
							advanceParams={advanceParams}
							showExportExcelForSAP={showExportExcelForSAP}
						/>
						{/* <div className="flex"> */}
						<div>
							<ErpSelection
								erpSelection={erpSelection?.erpSource}
								erpSource={erpType}
								defaultSource={erpSelection?.defaultSource}
								changeErpSelection={changeErpSelection}
							/>
						</div>
						{enableYearSelection && (
							<YearSelectionComponent onValueChange={setYear} year={year} />
						)}
						{createUser ? (
							<div>
								<Button
									type="primary"
									className="right-0 w-56 flex-auto"
									onClick={() => createUser()}>
									<span className="pr-10 relative bottom-1">
										{t.CreateUser}
									</span>
									<AddLightIcon />
								</Button>
							</div>
						) : null}
						{year === new Date().getFullYear() && (
							<IsCreateCount
								className="flex-auto"
								createCount={createCount}
								isCanCreate={isCanCreate}
								loading={loading}
							/>
						)}
						{/* </div> */}
					</div>
				</div>

				<Table
					locale={{
						cancelSort: 'Click to sort',
					}}
					bordered
					summary={currentData =>
						enableSummary
							? enableSummary(currentData, summaryData, columnsInit, hiddenCols)
							: null
					}
					rowClassName={(record, index) => getBackgroundColor(index)}
					style={{ fontSize: '16px', fontWeight: 'bold', minHeight: '700px' }}
					showSorterTooltip
					className="px-5  pb-20"
					rowKey={record => record.id}
					rowSelection={
						erpSelection && erpType === erpSelection?.defaultSource
							? getSelectionType(selectionType, rowSelection)
							: null
					}
					columns={columns}
					dataSource={data}
					onChange={handleTableChange}
					scroll={{ x: 'max-content', y: 475 }}
					pagination={
						perPage
							? {
									current: paginate,
									total: totalRecords,
									defaultPageSize: perPage,
									showSizeChanger: true,
									pageSizeOptions: ['5', '10', '20', '50', '100'],
							  }
							: false
					}
					loading={isFetching}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	country: selectUserCountry,
});
const mapDispatchToProps = dispatch => ({
	setErpType: erpType => dispatch(setErpTypeAsync(erpType)),
	updateErpData: (country, erpType, year) =>
		dispatch(
			searchForLocationAsync({
				erpType,
				country,
				year,
				searchField: '',
				limit: DEFAULT_LIMIT_START,
				offset: DEFAULT_OFFSET_START,
				coulumnName: 'cac',
				sortkey: 'asc',
			}),
		),
	updateFilterData: (erpType, country) =>
		dispatch(getLocationFiltersStartAsync(erpType, country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
